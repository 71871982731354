<template>
  <div>
    <el-form :model="form" class="weekform">
      <div class="text">第{{ id }}周</div>
      <el-form-item label="开始时间" prop="startTime">
        <el-date-picker
          v-model="form.startTime"
          type="datetime"
          placeholder="选择日期"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间" prop="endTime">
        <el-date-picker
          v-model="form.endTime"
          type="datetime"
          placeholder="选择日期"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-button type="primary" @click="handleClick">删除周</el-button>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "OenOen",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    startTime: {
      type: [String,Date],
      required: true,
    },
    endTime: {
       type: [String,Date],
      required: true,
    },
  },
  data() {
    return {
      form: {
        startTime: this.startTime,
        endTime: this.endTime,
      },
    };
  },
  watch: {
    startTime(newVal) {
      this.form.startTime = newVal;
    },
    endTime(newVal) {
      this.form.endTime = newVal;
    },
  },
  methods: {
    handleClick() {
    // 使用Element UI的MessageBox弹出确认对话框
    this.$confirm('你确定删除吗?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      // 用户点击了确定按钮
      this.$emit("delete", this.id);
    }).catch(() => {
      // 用户点击了取消按钮或关闭了对话框
    })}}
};
</script>
<style lang='less' scoped>
.editor {
  line-height: normal !important;
  height: fit-content;
  width: 375px;
  padding: 0 !important;
}
.weekform {
  padding: 20px 0;
  display: flex;
  align-items: center;
  .quill-editor {
    width: 375px !important;
    height: 777px !important;
  }
  .text {
    height: 33px;
    line-height: 33px;
    margin-right: 20px;
  }
  /deep/.el-form-item {
    margin-bottom: 0px;
  }
  /deep/.el-form-item__content {
    width: 350px;
  }
}
</style>
